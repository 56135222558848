<template>
  <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6 13.8H4.4C2.5 13.8 1 12.3 1 10.4
    C1 8.5 2.5 7 4.4 7C4.8 7 5.1 7.1 5.5 7.2
    C5.3 6.8 5.3 6.3 5.3 5.9C5.3 3.2 7.4 1 10.1 1
    C12.3 1 14.2 2.5 14.8 4.5C15.3 4.4 15.7 4.3 16.3 4.3
    C18.9 4.3 21 6.4 21 9C21 11.6 18.9 13.8 16.2 13.8H15"
    :stroke="color" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Group',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
